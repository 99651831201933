'use strict';

Gri.module({
  name: 'tab-alpha',
  ieVersion: null,
  $el: $('.tab-alpha'),
  container: '.tab-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $tabContent = $this.find('.tab-content');
    let option = {
      items: 1,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        }
      }
    };
    // endregion

    // region run
    $(window).on('resize', resizeController);
    resizeController();
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions
    function resizeController() {
      let windowW = $(window).width();
      if(windowW < 992) {
        $tabContent.find('.tab-pane').addClass('owl-carousel');
        $tabContent.find('.tab-pane').owlCarousel(option);
      } else {
        $tabContent.find('.tab-pane').removeClass('owl-carousel');
        $tabContent.find('.tab-pane').owlCarousel('destroy');
      }
    }
    // endregion

  }
});
